<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="7"
      >
        <v-card outlined>
          <v-card-text>
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th
                      style="width: 50px"
                      class="text-center"
                    >
                      #
                    </th>
                    <th>{{ $t('Logo') }}</th>
                    <th>{{ $t('Trademark Ownership Order') }}</th>
                    <th>{{ $t('Trademark Classification') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in apiData"
                    :key="index"
                  >
                    <td class="text-center">
                      {{ index + 1 }}
                    </td>
                    <td class="py-1">
                      <a
                        :href="`${$_file_path()}${row.logo}?token=${$_auth_token()}`"
                        target="_blank"
                      >
                        <div style="width: 50px; height: 50px">
                          <img
                            :src="`${$_file_path()}${row.logo}?token=${$_auth_token()}`"
                            style="width: 100%; height: 100%; object-fit: cover"
                            class="rounded"
                            alt=""
                          >
                        </div>
                      </a>
                    </td>
                    <td class="py-1">
                      <a
                        v-if="row.ownership_order_attachment"
                        :href="`${$_file_path()}${row.ownership_order_attachment}?token=${$_auth_token()}`"
                        target="_blank"
                      >
                        <div style="width: 50px; height: 50px">
                          <img
                            v-if="$_is_image(row.ownership_order_attachment)"
                            :src="`${$_file_path()}${row.ownership_order_attachment}?token=${$_auth_token()}`"
                            style="width: 100%; height: 100%; object-fit: cover"
                            class="rounded"
                            alt=""
                          >
                          <span v-else>
                            <v-icon
                              v-if="$_is_pdf(row.ownership_order_attachment)"
                              style="font-size: 42px"
                            >
                              far fa-file-pdf
                            </v-icon>
                          </span>
                        </div>
                      </a>
                    </td>
                    <td>
                      <div style="white-space: pre-line">
                        {{ row.activity }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {

  data() {
    return {
      apiData: [],
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    async getData() {
      await axios.get(`company-business-symbol/client/`).then(res => {
        this.apiData = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
